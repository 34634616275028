import React, { Component } from "react";
import './App.css';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectNum: '3',
      selectType: 'artists',
      selectTimeframe: 'short_term',
      user_name: '',
      user_image: '',
      tableData: [
        { id: 1, image: "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D", name: 'Loading', link: '' }
     ]
    }
    this.updateTables = this.updateTables.bind(this);
    this.updateUserData = this.updateUserData.bind(this);
    this.setSelectNum = this.setSelectNum.bind(this);
    this.setSelectType = this.setSelectType.bind(this);
    this.setSelectTimeframe= this.setSelectTimeframe.bind(this);
    this.getData = this.getData.bind(this);
    this.login = this.login.bind(this);
  }

  componentDidMount() {
    this.getData();
    this.sortTable = this.sortTable.bind(this);
  }

  componentDidUpdate(_, prevState) {
    if (this.state.selectNum !== prevState.selectNum ||
      this.state.selectTimeframe !== prevState.selectTimeframe ||
      this.state.selectType !== prevState.selectType)
    {
        this.getData();
    }
  }

  renderUserData() {
    return (
      <div>
        <img className="user_image" src={this.state.user_image} alt="user_image" width="50" height="50"></img>
        <br></br>
        <label>Hi {this.state.user_name}, here's your top</label>
        <br></br>
      </div>
    );
  }

  renderHeaders() {
    if (this.state.selectType === "artists") {
      return (
        <tbody>
          <tr>
            <th className="t-center" onClick={() => this.sortTable("id")}>#</th>
            <th></th>
            <th className="t-left" onClick={() => this.sortTable("name")}>Name</th>
            <th></th>
          </tr>
        </tbody>
      )
    }
    else {
      return(
        <tbody>
          <tr>
          <th className="t-center" onClick={() => this.sortTable("id")}>#</th>
          <th></th>
          <th className="t-left" onClick={() => this.sortTable("artist")}>Artist</th>
          <th className="t-left" onClick={() => this.sortTable("name")}>Track</th>
          <th className="t-left" onClick={() => this.sortTable("album")}>Album</th>
          <th></th>
          </tr>
        </tbody>
      )
    }
  }


  renderTableData() {
    if (this.state.selectType === "artists") {
      return this.state.tableData.map((tableDatum, index) => {
        const { id, image, name, link } = tableDatum
        return (
          <tbody key={Math.random()}>
            <tr key={id}>
              <td className="t-center" >{id}</td>
              <td className="t-center"><img src={image} alt="artist_image" width="20%" height="auto"></img></td>
              <td>{name}</td>
              <td>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <img src="spotify.png" width="20" height="20" alt="spotify_logo" />
                  </a>
                </td>
            </tr>
          </tbody>
        )
      })
    }
    else {
      return this.state.tableData.map((tableDatum, index) => {
        const { id, image, name, album, artist, link } = tableDatum
        return (
          <tbody key={Math.random()}>
            <tr key={id}>
              <td className="t-center" >{id}</td>
              <td className="t-center"><img src={image} alt="track_image" width="20%" height="auto"></img></td>
              <td>{artist}</td>
              <td>{name}</td>
              <td>{album}</td>
              <td>
                  <a href={link} target="_blank" rel="noopener noreferrer">
                    <img src="spotify.png" width="20" height="20" alt="spotify_logo" />
                  </a>
                </td>
            </tr>
          </tbody>
        )
      })
    }
 }

  setSelectNum(event) {
    this.setState({selectNum: event.target.value})
  }

  setSelectType(event) {
    this.setState({selectType: event.target.value})
  }

  setSelectTimeframe(event) {
    this.setState({selectTimeframe: event.target.value})
  }

  updateUserData(responseJson) {
    if (responseJson.images.length > 0) {
      this.setState({
        user_image: (responseJson.images[0].url === undefined) ? "profile.png" : responseJson.images[0].url
      });
    }
    else {
      this.setState({
        user_image: "profile.png"
      });
    }
    this.setState({
      user_name: (responseJson.display_name === undefined) ? "N/A" : responseJson.display_name
    });
  }

  updateTables(responseJson) {
    var newData = [];
    if (this.state.selectType === "artists") {
      for (var key1 of Object.keys(responseJson.items)) {
        var name = (responseJson.items[key1].name === undefined) ? "error" : responseJson.items[key1].name;
        var image = "profile.png"
        if (responseJson.items[key1].images.length > 0) {
          image = (responseJson.items[key1].images[0].url === undefined) ? "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D" : responseJson.items[key1].images[0].url;
        }
        var id = parseInt(key1) + 1;
        var link = (responseJson.items[key1].external_urls.spotify === undefined) ? "https://open.spotify.com/" : responseJson.items[key1].external_urls.spotify;
        var newEntry = {name: name, image: image, id: id, link: link};
        newData.push(newEntry);
      }
      this.setState({
        tableData: newData
      });
      this.renderTableData();
    }
    else {
      for (var key2 of Object.keys(responseJson.items)) {
        var name2 = (responseJson.items[key2].name === undefined) ? "error" : responseJson.items[key2].name;
        var album = (responseJson.items[key2].album.name === undefined) ? "error" : responseJson.items[key2].album.name;
        var artist = (responseJson.items[key2].artists[0].name === undefined) ? "error" : responseJson.items[key2].artists[0].name;
        var image2 = "profile.png"
        if (responseJson.items[key2].album.images.length > 0) {
          image2 = (responseJson.items[key2].album.images[0].url === undefined) ? "data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs%3D" : responseJson.items[key2].album.images[0].url;
        }
        var id2 = parseInt(key2) + 1;
        var link2 = (responseJson.items[key2].external_urls.spotify === undefined) ? "https://open.spotify.com/" : responseJson.items[key2].external_urls.spotify;
        var newEntry2 = {name: name2, image: image2, id: id2, link: link2, album: album, artist: artist};
        newData.push(newEntry2);
      }
      this.setState({
        tableData: newData
      });
      this.renderTableData();
    }
  }

  // SPOTIFY STUFF

  login() {    
    /**
     * Obtains parameters from the hash of the URL
     * @return Object
     */
    function getHashParams() {
      var hashParams = {};
      var e, r = /([^&;=]+)=?([^&;]*)/g,
          q = window.location.hash.substring(1);
      while ((e = r.exec(q))) {
         hashParams[e[1]] = decodeURIComponent(e[2]);
      }
      return hashParams;
    }

    /**
     * Generates a random string containing numbers and letters
     * @param  {number} length The length of the string
     * @return {string} The generated string
     */
    function generateRandomString(length) {
      var text = '';
      var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

      for (var i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
    };

        var params = getHashParams();

        var access_token = params.access_token;

          if (access_token) {
            return access_token;
          } else {
            var client_id = '66980d6078af443e8940f3a453f40b35'; // Your client id
            var redirect_uri = 'https://spot.zrich.dev'; // Your redirect uri
            var stateSpot2 = generateRandomString(16);
            var scope = 'user-top-read user-read-email user-read-private';
            var url = 'https://accounts.spotify.com/authorize';
            url += '?response_type=token';
            url += '&client_id=' + encodeURIComponent(client_id);
            url += '&scope=' + encodeURIComponent(scope);
            url += '&redirect_uri=' + encodeURIComponent(redirect_uri);
            url += '&state=' + encodeURIComponent(stateSpot2);
            window.location = url;
    }
  }

  sortTable(colName) {
    var data = this.state.tableData;
    var data1 = JSON.parse(JSON.stringify(this.state.tableData)).sort((a, b) => (a[colName] > b[colName]) ? 1 : -1);
    var data2 = JSON.parse(JSON.stringify(this.state.tableData)).sort((a, b) => (a[colName] < b[colName]) ? 1 : -1);
    for (var i = 0; i < data.length - 1; i++) {
        if (data[i][colName] !== data1[i][colName]) {
            this.setState({tableData: data1});
            return;
        }
    }
    this.setState({tableData: data2});
    return;
  }

  getData() {
    var headers = new Headers();
    headers.append("Authorization", "Bearer " + this.login());
    
    var requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    };

    fetch("https://api.spotify.com/v1/me/top/" + this.state.selectType + "?time_range=" + this.state.selectTimeframe + "&limit=" + this.state.selectNum, requestOptions)
      .then((response) => response.json())
      .then((responseJson) => {
        this.updateTables(responseJson)})
      .catch(error => console.log('error', error));

    fetch("https://api.spotify.com/v1/me", requestOptions)
      .then((response2) => response2.json())
      .then((responseJson2) => {
        this.updateUserData(responseJson2)})
      .catch(error => console.log('error', error));
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="column">
          <img src="banner.png" alt="banner" width="150" height="75"/>
          </div>
        </div>
        <div className="row row-centered">
        {this.renderUserData()}
        <select name="selectNum" id="selectNum" onInput={this.setSelectNum.bind(this)}>
          <option value="3">3</option>
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="50">50</option>
        </select>
        <label> </label>
        <select name="selectType" id="selectType" onInput={this.setSelectType.bind(this)}>
          <option value="artists">artists</option>
          <option value="tracks">tracks</option>
        </select>
        <label> from </label>
        <select name="selectTimeframe" id="selectTimeframe" onInput={this.setSelectTimeframe.bind(this)}>
          <option value="short_term">the past 30 days</option>
          <option value="medium_term">the past 6 months</option>
          <option value="long_term">all time</option>
        </select>          
        <br></br>
        <br></br>
        <table ref={this.myRef}>
            {this.renderHeaders()}
            {this.renderTableData()}
        </table>
        </div>
        <div className="header">
          <form action="https://resume.zrich.dev/">
            <button className="about" type="submit">About The Author</button>
          </form>
        </div>
      </div>
    );
  }
}

export default App;